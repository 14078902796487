import "./App.css";
import tw from "twin.macro";
import Phiof from "./Components/Phiof";
import PageCluster from "./Components/PageCluster";

// 23503e - blue
// d2953c - orange
// 23503e - green

const AppContainer = tw.div`
flex
flex-col
w-full
h-full
overflow-hidden 
`;

const Footer = tw.h3`
flex
text-center
justify-center
w-full
font-[Regular]
text-black
pt-10
p-8
text-[15px]`;

function App() {
  return (
    <AppContainer>
      <Phiof />
      <PageCluster />
      <Footer>© Phiof Digital 2024</Footer>
    </AppContainer>
  );
}

export default App;
