import React from "react";
import "../../App.css";
import tw from "twin.macro";
import Collapsible from "react-collapsible";
import ContactForm from "../ContactForm";
import Cards from "../Cards";

// 23503e - blue
// d2953c - orange
// 23503e - green

const AppContainer = tw.div`
    w-full
    h-auto
    bg-[#23503e]
    flex
    flex-col
    text-center	
    relative
    pt-1
    pb-1
    items-center`;

const H4 = tw.h4`
flex
text-center 
w-full
pt-5
pb-5
pl-[10vw]
pr-[10vw]
font-[Regular]
text-black
text-[20px]
transition-all
ease-out
duration-1000	
`;

function PageCluster() {
  return (
    <AppContainer>
      <Collapsible
        classParentString=" w-full
        h-auto
        bg-[#23503e]
        flex
        flex-col
        text-center	
        relative
        items-center"
        triggerClassName="w-full
        font-[Regular]
        text-black
        sm:text-[90px]
        text-[70px]
        hover:text-[black]
        hover:text-opacity-100
        hover:bg-[#d2953c]
        transition-all
        ease-out
        duration-1000	"
        triggerOpenedClassName="w-full
        font-[Regular]
        text-black
        sm:text-[90px]
        text-[70px]
        hover:text-[black]
        hover:text-opacity-100
        hover:bg-[#d2953c]
        transition-all
        ease-out
        duration-1000	"
        trigger={"About Us"}
      >
        <H4>
          In a digital landscape often clouded by jargon and opaque practices,
          Phiof Digital stands as a beacon of clarity and understanding. We’re
          not just another web development and management company; we’re your
          partners in demystifying the digital world, one project at a time.
        </H4>
        <H4>
          Founded on the principles of transparency, personal understanding, and
          ethical technology use, our mission is simple: to transform the way
          businesses and individuals experience the web. We believe that
          everyone deserves access to clear, understandable, and honest
          information about the services they use and the processes involved in
          creating their digital presence.
        </H4>
      </Collapsible>
      {/* <Collapsible
        classParentString=" w-full
        h-auto
        bg-[#23503e]
        flex
        flex-col
        text-center	
        relative
        items-center"
        triggerClassName="w-full
        font-[Regular]
        text-black
        sm:text-[90px]
        text-[70px]
        hover:text-[black]
        hover:text-opacity-100
        hover:bg-[#d2953c]
        transition-all
        ease-out
        duration-1000	"
        triggerOpenedClassName="w-full
        font-[Regular]
        text-black
        sm:text-[90px]
        text-[70px]
        hover:text-[black]
        hover:text-opacity-100
        hover:bg-[#d2953c]
        transition-all
        ease-out
        duration-1000	"
        trigger={"Our Work"}
      >
        <Cards />
      </Collapsible> */}
      <Collapsible
        classParentString=" w-full
        h-auto
        bg-[#23503e]
        flex
        flex-col
        text-center	
        relative
        items-center"
        triggerClassName="w-full
        font-[Regular]
        text-black
        sm:text-[90px]
        text-[70px]
        hover:text-[black]
        hover:text-opacity-100
        hover:bg-[#d2953c]
        transition-all
        ease-out
        duration-1000	"
        triggerOpenedClassName="w-full
        font-[Regular]
        text-black
        sm:text-[90px]
        text-[70px]
        hover:text-[black]
        hover:text-opacity-100
        hover:bg-[#d2953c]
        transition-all
        ease-out
        duration-1000	"
        trigger={"Contact us"}
      >
        <ContactForm />
      </Collapsible>
    </AppContainer>
  );
}

export default PageCluster;
