import { useState } from "react";
import emailjs from "@emailjs/browser";
import tw from "twin.macro";
import styled from "styled-components";
import backgroundImage from "./CounsellingCare.png";

// 23503e - blue
// d2953c - orange
// 23503e - green

const AppContainer = tw.form`
    w-full
    overflow-scroll
    bg-[#23503e]
    flex
    flex-row
    items-center
    content-center
    justify-center
    text-center	
    relative
    pt-1
    pb-1	`;

const Lab = tw.label`
w-auto
font-[Regular]
text-black
text-[30px]
p-5
`;

const InputText = tw.input`
flex
text-center
w-[50vw]
font-[Regular]
text-[#23503e]
hover:bg-[#d2953c]
hover:border-[#d2953c]
hover:text-black
text-[30px]
bg-[#23503e]
bg-black
border
border-4
rounded-xl
border-black
transition-all
ease-out
duration-1000
`;

const InputButton = tw.input`
w-auto
h-auto
font-[Regular]
text-[#23503e]
text-[30px]
bg-[#23503e]
pl-2
pr-2
border
border-4
rounded-xl
border-black
bg-[black]
hover:bg-[#d2953c]
hover:border-[#d2953c]
hover:text-black
transition-all
ease-out
duration-1000`;

const CardContainer = tw.div`
z-10
flex
items-center
place-content-center
content-center
relative
text-center
w-[200px]
h-[500px]
transition-all
ease-in-out
duration-500
`;

const CardOver = tw.div`
z-20
flex
relative
items-center
place-content-center
content-center
bg-black/50
p-[100px]
w-[200px]
h-[500px]
hover:invert
hover:bg-black/10
object-cover	
border
border-0
rounded-[100px]
transition-all
ease-in-out
duration-500
`;

const Card = styled.button`
  ${tw`
z-0
flex
relative
items-center
place-content-center
content-center
p-5
w-[200px]
h-[500px]
object-cover	
border
border-0
rounded-[100px]
transition-all
ease-in-out
duration-500`}
  background-image: url(${backgroundImage});
  background-size: cover;
`;

const H4 = tw.h4`
z-30
relative
opacity-[100%]
font-[Regular]
text-white
text-[40px]
`;

const Cards = () => {
  return (
    <AppContainer>
      <CardContainer>
        <Card>
          <CardOver>
            <H4>test</H4>
          </CardOver>
        </Card>
      </CardContainer>
    </AppContainer>
  );
};
export default Cards;
