import { useState } from "react";
import emailjs from "@emailjs/browser";
import tw from "twin.macro";

// 23503e - blue
// d2953c - orange
// 23503e - green

const AppContainer = tw.form`
    w-full
    h-auto
    bg-[#23503e]
    flex
    flex-col
    text-center	
    relative
    pt-1
    pb-1
    items-center
    transition-all
ease-out
duration-1000
    	`;

const Lab = tw.label`
w-auto
font-[Regular]
text-black
text-[30px]
p-5
`;

const Text = tw.h3`
flex
text-center
w-[50vw]
font-[Regular]
text-[#23503e]
hover:bg-[#d2953c]
hover:border-[#d2953c]
hover:text-black
text-[30px]
bg-[#23503e]
bg-black
border
border-4
rounded-xl
border-black
cursor-default
transition-all
ease-out
duration-1000
`;

const InputText = tw.input`
flex
text-center
w-[50vw]
font-[Regular]
text-[#23503e]
hover:bg-[#d2953c]
hover:border-[#d2953c]
hover:text-black
text-[30px]
bg-[#23503e]
bg-black
border
border-4
rounded-xl
border-black
transition-all
ease-out
duration-1000
`;

const InputButton = tw.input`
w-auto
h-auto
font-[Regular]
text-[#23503e]
text-[30px]
bg-[#23503e]
pl-2
pr-2
border
border-4
rounded-xl
border-black
bg-black
hover:bg-[#d2953c]
hover:border-[#d2953c]
hover:text-black
transition-all
ease-out
duration-1000`;

const TextArea = tw.textarea`
flex
text-center
w-[50vw]
font-[Regular]
text-[#23503e]
text-[30px]
bg-[black]
border
border-4
rounded-xl
border-[black]
hover:bg-[#d2953c]
hover:border-[#d2953c]
hover:text-black
transition-all
ease-out
duration-1000
`;

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const [isSubmittingMsg, setIsSubmittingMsg] = useState(false);
  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    setIsSubmittingMsg(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        { publicKey: process.env.REACT_APP_PUBLIC_KEY }
      )
      .then(
        (result) => {
          setStateMessage("Message sent!");
          setIsSubmitting(false);
          console.log(result);
          setTimeout(() => {
            setStateMessage(null);
            setIsSubmittingMsg(false);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          setStateMessage("Something went wrong, please try again later");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );

    // Clears the form after sending the email
    e.target.reset();
  };
  return (
    <>
      {isSubmittingMsg ? (
        <AppContainer>
          <Text>
            Thanks for your submission, we will be in contact shortly!
          </Text>
        </AppContainer>
      ) : (
        <AppContainer onSubmit={sendEmail}>
          <Lab>Name</Lab>
          <InputText type="text" name="user_name" />
          <Lab>Email</Lab>
          <InputText type="email" name="user_email" />
          <Lab>Message</Lab>
          <TextArea name="message" />
          <div className="pt-5">
            <InputButton type="submit" value="Send" disabled={isSubmitting} />
          </div>
          {stateMessage && <p>{stateMessage}</p>}
        </AppContainer>
      )}
    </>
  );
};
export default ContactForm;
