import "../../App.css";
import tw from "twin.macro";
import Marquee from "react-fast-marquee";
import Helmet from "react-helmet";

// 5ab9a9 - blue
// d2953c - orange
// 23503e - green

const AppContainer = tw.div`
flex
items-center
place-content-center
relative
w-full
h-screen
pb-10
bg-[#23503e]`;

const Text1 = tw.div`
z-0
absolute
font-[Regular]
text-black
sm:text-[30vh]
md:text-[18vh]
text-[12vh]
sm:pb-[35vh]
md:pb-[28vh]
pb-[20vh]`;

const MarqueeText = tw.div`

font-[Regular]
text-[#23503e]
text-[3vh]`;

const Text2 = tw.div`
z-0
absolute
font-[Regular]
text-black
sm:tracking-[20px]
sm:text-[30vh]
md:text-[18vh]
text-[13vh]
sm:pt-[35vh]
md:pt-[28vh]
pt-[20vh]`;

const WidthRestrict = tw.div`
z-0
absolute
w-[80vw]`;

const Border = tw.div`
border
border-solid
border-black
rounded-full
bg-black
pt-4
pb-4
overflow-clip`;

function Phiof() {
  return (
    <AppContainer>
      <Helmet bodyAttributes={{ style: "background-color : #23503e" }} />
      <Text1>PHIOF</Text1>
      <WidthRestrict>
        <Border>
          <Marquee>
            <MarqueeText>
              Website Development ∙ Website Recovery & Migration ∙ Website
              Hosting ∙ Website Management ∙ Website Problem-solving ∙ &nbsp;
            </MarqueeText>
          </Marquee>
        </Border>
      </WidthRestrict>
      <Text2>DIGITAL</Text2>
    </AppContainer>
  );
}

export default Phiof;
